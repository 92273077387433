body {
  margin: 0;
  color: #fff;
  font-family: "Kanit";
}

html, #root{
  height: 100%;
  top: 0;
  color: #fff;
  font-family: "Kanit";
  pointer-events: auto;
}
/* #div {
  transition: background-color 0.3s ease;
}
#div :hover {
  background-color: black;
} */
.loading-end {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.loadingDot {
  animation: loadingDotBlink 4s steps(1, end) infinite;
}
@keyframes loadingDotBlink {
  0% { content: ' '; }
  25% { content: ' .'; }
  50% { content: ' ..'; }
  75% { content: ' ...'; }
}
.loadingDot::after {
  content: '';
  animation: loadingDotBlink 1.5s steps(4, end) infinite;
}

@keyframes PopupBottom {
  0% { transform: translateY(200%); }
  100% { transform: translateY(0%); }
}

.topBarShortCutLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.topBarShortCutLink div {
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
}
.hamburger {
  /* display: none; */
  display: flex;
  cursor: pointer;
  height: 30px;
}
.hamburgerMenu {
  display: block;
  position: absolute;
  top: 60px;
  right: 5px;
  border-radius: 10px;
  background: rgba(75, 0, 0, 0.75);
  min-width: 40%;
  overflow-x: auto;
  /* scrollbar-color: rgb(225, 100, 100) #00000000;
  scrollbar-width: thin; */
  /* max-height: 0; */
  max-height: calc(100vh - 60px);
  animation: mobile-active-hide 0.5s forwards;
}
.hamburgerMenu.active {
  display: block;
  position: absolute;
  top: 60px;
  right: 5px;
  border-radius: 10px;
  background: rgba(75, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  width: 40%;
  /* scrollbar-color: rgb(225, 100, 100) #00000000;
  scrollbar-width: thin; */
  /* max-height: 0; */
  max-height: calc(100vh - 60px);
  animation: mobile-active-show 0.5s forwards;
}
.hamburgerMenu div {
  border-radius: 10px;
  border-bottom: 3px solid rgb(250, 100, 100);
  padding: 10px 20px;
}
.OhamburgerMenu a {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    text-decoration: none;
    /* border-bottom: 3px solid #707070; */
    /* border-radius: 10px; */
    animation: mobile-active-hide-a 0.2s forwards;
}
.OhamburgerMenu.active a {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    text-decoration: none;
    /* border-bottom: 3px solid #707070; */
    /* border-radius: 10px; */
    animation: mobile-active-show-a 0.2s forwards;
}
@keyframes mobile-active-show {
  0%{max-height: 0;opacity: 0;}
  100%{max-height: calc(100vh - 60px);opacity: 1;}
}
@keyframes mobile-active-hide {
  0%{max-height: calc(100vh - 60px);opacity: 1;}
  100%{max-height: 0;opacity: 0;}
}
@keyframes mobile-active-show-a {
  0%{opacity: 0;}
  100%{opacity: 1;}
}
@keyframes mobile-active-hide-a {
  0%{opacity: 1;}
  100%{opacity: 0;}
}
.profile {
  display: none;
}
.homePage1 {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100vh;
  border-bottom: 2px solid rgb(144, 144, 144);
}
.homePage1-2 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.dashMenu {
  overflow: auto;
  scrollbar-width: none;
  background-color: #ff666666;
  height: 85vh;
  border-radius: 30px;
  transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
}
.dashMenuArrow {
  display: none;
  transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  background-color: rgba(255, 138, 138, 0.5);
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 10px;
}
.dashMenuBackDrop {
  display: none;
}
.dashCategory {
  z-index: 5;
  min-width: fit-content;
  display: flex;
  flex-direction: row;
  transition: all 0.2s cubic-bezier(0.8, 0, 0.2, 1);
}
[data-tag]::after {
  content: var(--after-content, '');
  position: absolute;
  bottom: 110%;
  left: 120%;
  width: max-content;
  z-index: 5;
  transform: translateX(-50%);
  border: 2px solid #e48383;
  background-color: #c61f1f;
  color: #fff;
  font-size: 15px;
  font-weight: 100;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: var(--after-opacity, 0);
  pointer-events: none;
  transition: opacity 0.3s ease;
}

@keyframes shake {
  0% { transform: translateX(0px); }
  25% { transform: translateX(10px); }
  50% { transform: translateX(0px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0px); }
}

@media (max-width: 1000px) {
  .profile {
    display: flex;
  }

  /* .hamburger {
      display: flex;
      cursor: pointer;
      height: 30px;
  } */

  .topBarShortCutLink {
    display: none;
  }

  .homePage1 {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 100px 0px;
    border-bottom: 2px solid rgb(144, 144, 144);
  }
  .homePage1-2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
  }
  
  .dashMenu {
    /* position: absolute; */
    z-index: 5;
    min-width: 50%;
    width: fit-content;
    backdrop-filter: blur(5px);
  }
  /* .dashMenu.show {
    transform: translateX(0);
  }
  .dashMenu.hide {
    transform: translateX(-110%);
  } */
  .dashMenuArrow {
    display: block;
  }
  .dashMenuArrow img {
    height: 30px;
  }
  .dashMenuArrow.hide {
    transform: translateX(0);
  }
  .dashMenuArrow.hide img {
    transform: rotateZ(180deg);
  }
  .dashMenuArrow.show {
    transform: translateX(0vw);
  }
  .dashMenuArrow.show img {
    transform: rotateZ(0deg);
  }
  .dashMenuBackDrop {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 1s;
  }
  .dashMenuBackDrop.show {
    display: block;
    animation: dashMenuBackDropShow 0.5s forwards;
  }
  .dashMenuBackDrop.hide {
    animation: dashMenuBackDropHide 0.5s forwards;
  }
  .dashCategory {
    position: absolute;
  }
  .dashCategory.show {
    transform: translateX(0);
  }
  .dashCategory.hide {
    transform: translateX(-110%);
  }
  @keyframes dashMenuBackDropShow {
    0% { background-color: #00000000;  backdrop-filter: blur(0); }
    100% { background-color: #0000003f;  backdrop-filter: blur(2px); }
  }
  @keyframes dashMenuBackDropHide {
    0% { background-color: #0000003f;  backdrop-filter: blur(2px); }
    99% {  background-color: #00000000;  backdrop-filter: blur(0); }
    100% { display: none; }
  }
}

.fade-up {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.fade-up.show {
  opacity: 1;
  transform: translateY(0);
}
.fade-up.hide {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgba(50, 50, 50, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(225, 100, 100);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(238, 75, 75);
}


.backdrop-blur {
  height: 100%;
  width: 100%;
  backdrop-filter: blur(2px);
  background-color: #00000075;
}



.preLoaderBar {
  position: fixed;
  top: 60px;
  z-index: 101;
  height: 100%;
  width: 100%;
}
.progress {
  position: fixed;
  z-index: 101;
  height: 3px;
  display: block;
  width: 100%;
  background-color: #ffffff00;
  border-radius: 2px;
  background-clip: padding-box;
  /*margin: 0.5rem 0 1rem 0;*/
  overflow: hidden;
}
.progress .indeterminate {
background-color:black; }
.progress .indeterminate:before {
  content: '';
  position: absolute;
  background-color: #ff8585;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
.progress .indeterminate:after {
  content: '';
  position: absolute;
  background-color: #ff8585;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s; }

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }




/* 
- #font 
*/

.kanit-thin {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: normal;
}
.kanit-extralight {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: normal;
}
.kanit-light {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.kanit-medium {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.kanit-semibold {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.kanit-bold {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.kanit-extrabold {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
}
.kanit-black {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.kanit-thin-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: italic;
}
.kanit-extralight-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: italic;
}
.kanit-light-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: italic;
}
.kanit-regular-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: italic;
}
.kanit-medium-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: italic;
}
.kanit-semibold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: italic;
}
.kanit-bold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.kanit-extrabold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: italic;
}
.kanit-black-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* 
- #background 
*/

/* #background {
  position: relative;
  position: sticky;
  top: 0;
  padding: 0;
  background: linear-gradient(135deg, rgb(0 0 0) 0%, rgb(39 0 0) 40%, rgb(153 0 0) 80%);
  border-bottom: 1px solid hsl(0deg 69.7% 56.37%);
  z-index: 0;
} */

/* 
- #cursor 
*/

.alias {cursor: alias;}
.all-scroll {cursor: all-scroll;}
.auto {cursor: auto;}
.cell {cursor: cell;}
.col-resize {cursor: col-resize;}
.context-menu {cursor: context-menu;}
.copy {cursor: copy;}
.crosshair {cursor: crosshair;}
.default {cursor: default;}
.e-resize {cursor: e-resize;}
.ew-resize {cursor: ew-resize;}
.grab {cursor: -webkit-grab; cursor: grab;}
.grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
.help {cursor: help;}
.move {cursor: move;}
.n-resize {cursor: n-resize;}
.ne-resize {cursor: ne-resize;}
.nesw-resize {cursor: nesw-resize;}
.ns-resize {cursor: ns-resize;}
.nw-resize {cursor: nw-resize;}
.nwse-resize {cursor: nwse-resize;}
.no-drop {cursor: no-drop;}
.none {cursor: none;}
.not-allowed {cursor: not-allowed;}
.pointer {cursor: pointer;}
.progress {cursor: progress;}
.row-resize {cursor: row-resize;}
.s-resize {cursor: s-resize;}
.se-resize {cursor: se-resize;}
.sw-resize {cursor: sw-resize;}
.text {cursor: text;}
.url {cursor: auto;}
.w-resize {cursor: w-resize;}
.wait {cursor: wait;}
.zoom-in {cursor: zoom-in;}
.zoom-out {cursor: zoom-out;}

/* 
- #HEADER 
*/
